import React, { useMemo, useState, useEffect } from "react";
import Preloader from "../../components/Preloader";
import { useLocation } from "react-router-dom";

function WrapperScroll({ Component }) {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useMemo(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  useEffect(() => {
    const timerId = setTimeout(() => {
      setLoading((state) => (state = false));
    }, 2000);
    return () => {
      clearTimeout(timerId);
      setLoading((state) => (state = true));
    };
  }, [location]);

  return (
    <>
      {loading ? <Preloader className="overlay" /> : null}
      <Component />
    </>
  );
}

export default WrapperScroll;
