import React from "react";
// import photo from "../assets/img/icons-13.png";
// import mainPhoto from "../assets/img/icons-14.png";
import { CallIcon, Phone } from "../components/Icons";
import { useSelector } from "react-redux";

function Call() {
  const { contact: { tel = "" } = {} } = useSelector((state) => state.test);
  return (
    <div className="call__wrapper">
      <a href={`tel:${tel}`} >
        <div className="call">
          <div className="call__img">
            <CallIcon className="call__image" />
          </div>
          <div className="call__text">
            <div>
              <p>ԿԱՊ</p>
            </div>
            <div className="call__text-phonePart">
              <div className="call__phoneImg">
                <Phone className="call__phoneImage" />
              </div>
              <div>
                <span>{tel}</span>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default Call;
