import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { request } from "../helperFunctions/requests";

function Goal() {
    const [data, setData] = useState({});
    useEffect(() => {
        const controller = new AbortController();
        const abort = { signal: controller.signal };

        try {
            (async () => {
                const { data } = await request("/api/goal", { method: "GET" });
                setData(data);
            })();
        } catch (err) {
            console.log(err);
        }

        return () => controller.abort();
    }, []);

    return (
        <div className="goal__contant wrapper__container">
            <Container>
                <Row className="goal__title titleOne">
                    <Col className="titleOne__wrapper">
                        <span>Նպատակ</span>
                    </Col>
                </Row>
                <Row className="goal__body subtitle">
                    <Col className="subtitle__text">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: data.description,
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Goal;
