import React, { useCallback, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import instance from "../services/api";
import Preloader from "../components/Preloader";
import ModalComponent from "components/Modal";
import { LabelRequired } from "../components/formFields";
import { ArrowButton } from "../components/common";
import phone from "../assets/img/icons-06.png";
import letter from "../assets/img/icons-21.png";
import addressImg from "../assets/img/address.png";

import { useSelector } from "react-redux";

function Contacts() {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [massage, setMassage] = useState("");
  const [borderErr, setBorderErr] = useState(false);
  // const [empatyInputs, setEmpatyInputs] = useState(false);
  const { contact: { tel = "", address = "", email = "" } = {} } = useSelector(
    (state) => state.test
  );

  // useState(() => {
  //   let timeoutId;
  //   console.log('100000');
  //   timeoutId = setTimeout(() => setEmpatyInputs(state => state = false), 100);
  // }, [empatyInputs]);

  const handleSubmit = useCallback(async (event, value) => {
    setLoading(true);

    await instance
      .post("/api/contactUs", value)
      .then(({ data }) => {
        setIsOpen(true);
        setMassage(data.message);
        setBorderErr(false);
      }).catch((err) => {
        setIsOpen(true);
        setMassage(err);
        setBorderErr(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="contacts__contant wrapper__container">
      <Container>
        <Row className="contacts__title titleOne">
          <Col className="titleOne__wrapper">
            <span>Կոնտակտներ</span>
          </Col>
        </Row>
        {loading && <Preloader className="overlay" />}
        <Row className="contacts__body">
          <Col lg={5} className="contacts__infoPart">
            <Row className="contacts__infoWrapper">
              <Col className="contacts__infoItems" lg={12}>
                <div className="contacts__infoItem">
                  <div className="contacts__infoImg">
                    <img src={phone} alt="" />
                  </div>
                  <a href={`tel:${tel}`}>
                    <div className="contacts__infoBody">
                      <p>Հեռախոս</p>
                      <p>
                        <span>{tel}</span>
                      </p>
                    </div>
                  </a>
                </div>
              </Col>
              <Col className="contacts__infoItems" lg={12}>
                <div className="contacts__infoItem">
                  <div className="contacts__infoImg">
                    <img src={letter} alt="" />
                  </div>
                  <a href={`mailto:${email}`}>
                    <div className="contacts__infoBody">
                      <p>Էլ․ հասցե</p>
                      <p>
                        <span>{email}</span>
                      </p>
                    </div>
                  </a>
                </div>
              </Col>
              <Col className="contacts__infoItems" lg={12}>
                <div className="contacts__infoItem active">
                  <div className="contacts__infoImg">
                    <img src={addressImg} alt="" />
                  </div>
                  <div className="contacts__infoBody">
                    <p>Հասցե</p>
                    <p>
                      <span>{address}</span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={7} className="contacts__sendEmail">
            <AvForm onValidSubmit={handleSubmit}>
              <Row>
                <Col sm={12}>
                  <AvField
                    required
                    name="name"
                    // errorMessage={''}
                    labelClass="label"
                    inputClass="input"
                    label={
                      <LabelRequired>
                        Անուն Ազգանուն / Կազմակերպության անվանում{" "}
                      </LabelRequired>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <AvField
                  type='email'
                    required
                    name="email"
                    // errorMessage={''}
                    labelClass="label"
                    inputClass="input"
                    label={<LabelRequired>Էլ․ հասցե</LabelRequired>}
                  />
                </Col>
                <Col sm={6}>
                  <AvField
                    required
                    name="phone"
                    // errorMessage={''}
                    validate={{
                      tel: {
                        pattern: /^(\+374|0)\d{8}$/,
                      },
                    }}
                    type="tel"
                    labelClass="label"
                    inputClass="input"
                    label={<LabelRequired>Հեռախոս</LabelRequired>}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="contacts__theLast" sm={12}>
                  <AvField
                    required
                    name="description"
                    labelClass="label"
                    className="input"
                    label={<LabelRequired>Նկարագիր</LabelRequired>}
                    type="textarea"
                    rows="4"
                  />
                </Col>
              </Row>
              <div className="form-footer">
                <ArrowButton text="Ուղարկել" />
              </div>
            </AvForm>
          </Col>
        </Row>
      </Container>
      <ModalComponent
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        massage={massage}
        borderErr={borderErr}
      />
    </div>
  );
}

export default Contacts;
