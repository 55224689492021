import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import OrganizedActionsItem from "../../../components/organized/OrganizedActions";
import { Link } from "react-router-dom";
import { request } from "../../../helperFunctions/requests";
import { correctDate } from "../../../helperFunctions/correctDate";

function BlogsAndNews() {
  const [data, setData] = useState([]);

  const correctData = useMemo(() => {
    let result = [...data];
    if (result.length > 3) {
      result = result.splice(0, 3);
      return result;
    }
    return result;
  }, [data]);

  useEffect(() => {
    (async () => {
      const { data } = await request(`/api/news`, {
        method: "GET",
      });
      setData(data);
    })();
  }, []);

  return (
    <div className="blogAndNews__contant wrapper__container">
      <Container>
        <Row className="blogAndNews__title titleOne">
          <Col className="titleOne__wrapper">
            <span>Բլոգ և նորություններ</span>
          </Col>
        </Row>
        <Row className="blogAndNews__wrapper handle__row25">
          {correctData.map((item) => {
            return (
              <Col
                key={item.id}
                className="upComingNews__cardsWrapper handle__col25"
                lg={4}
              >
                <Link to={`/upcoming-news/${item.id}`}>
                  <OrganizedActionsItem
                    id={item.id}
                    img={item.image}
                    date={correctDate(item.date)}
                    title={item.title}
                    text={item.description}
                  />
                </Link>
              </Col>
            );
          })}
        </Row>
        <Row className="paperInfo__linkWrapper">
          <Col className="paperInfo__link">
            <Link to="/upcoming-news">
              <span>Տեսնել բոլորը</span>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BlogsAndNews;
