import React, { useCallback, useMemo, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import instance from "services/api";
import Preloader from "../components/Preloader";
import { Years, Month, Days } from "../utils/utils";
import picture from '../assets/img/111.png';

import { LabelRequired, AvSelect, ImageUpload } from "../components/formFields";
import { Title, ArrowButton } from "../components/common";

function Sale() {
  const [loading, setLoading] = useState(false);
  const year = useMemo(() => Years(), []);
  const month = useMemo(() => Month(), []);
  const days = useMemo(() => Days(), []);

  const handleSubmit = useCallback(async (event, value) => {
    setLoading(true);
    await instance.post("/api/sale", {
      name: value.name,
      surname: value.surname,
      middlename: value.middlename,
      file: value.file,
      university_name: value.university_name,
      qualification: value.qualification,
      year: value.year,
      birthday: value.day + "-" + value.month + "-" + value.birthday_year,
    });
    setLoading(false);
  }, []);

  return (
    <div className="page-sale wrapper__container">
      {loading && <Preloader className="overlay" />}
      <Container>
        <Title title="Մենք այստեղ ենք, որպեսզի օգնենք միմյանց" />
        <Row>
          <Col lg="5">
            <div className='sale__image' >
              <img src={picture} />
            </div> 
          </Col>
          <Col lg="7">
            <AvForm onValidSubmit={handleSubmit}>
              <Row>
                <Col sm="4">
                  <AvField
                    required
                    name="name"
                    // errorMessage={''}
                    labelClass="label"
                    inputClass="input"
                    label={<LabelRequired>Անուն</LabelRequired>}
                  />
                </Col>
                <Col sm="4">
                  <AvField
                    name="surname"
                    // errorMessage={''}
                    className="input"
                    labelClass="label"
                    label={<LabelRequired>Ազգանուն</LabelRequired>}
                    required
                  />
                </Col>
                <Col sm="4">
                  <AvField
                    name="middlename"
                    // errorMessage={''}
                    label={<LabelRequired>Հայրանուն</LabelRequired>}
                    className="input"
                    labelClass="label"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <ImageUpload
                  className="lg"
                  name="file"
                  url="sale"
                  // fileFunc={fileFunc}
                />
              </Row>
              <Row>
                <Col sm="4">
                  <AvSelect
                    inputClass="input"
                    label={<LabelRequired>Ծննդյան</LabelRequired>}
                    labelClass="label"
                    name="day"
                    required
                    options={days}
                  />
                </Col>
                <Col sm="4">
                  <AvSelect
                    inputClass="input"
                    label={<label></label>}
                    labelClass="label"
                    name="month"
                    required
                    options={month}
                  />
                </Col>
                <Col sm="4">
                  <AvSelect
                    inputClass="input"
                    label={<label></label>}
                    labelClass="label"
                    name="birthday_year"
                    options={year}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <AvField
                    name="university_name"
                    // errorMessage={''}
                    label={<LabelRequired>ԲՈՒՀ-ի անվանումը</LabelRequired>}
                    className="input"
                    labelClass="label"
                    required
                  />
                </Col>
                <Col sm="6">
                  <AvField
                    name="qualification"
                    // errorMessage={''}
                    label={<LabelRequired>Որակավորում</LabelRequired>}
                    className="input"
                    labelClass="label"
                    required
                  />
                </Col>
                <Col sm="6">
                  <AvSelect
                    label={<LabelRequired>Տարեթիվ</LabelRequired>}
                    className="text-capitalize input"
                    labelClass="label"
                    name="year"
                    options={year}
                    required
                  />
                </Col>
              </Row>
              <div className="form-footer">
                <ArrowButton text="Դիմել" />
              </div>
            </AvForm>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Sale;
