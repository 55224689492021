import React from "react";
import { Container, Row, Col } from "reactstrap";

function ArrowUp() {
  return (
    <div className='arrow__up-content' >
      <Container className="arrow__up-wrapper">
        <Row className="arrow__up">
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
}

export default ArrowUp;
