import React from 'react';
import AvField from 'availity-reactstrap-validation/lib/AvField';

const memo = {};
const promises = {};

const AvSelect = ({ ep, options = [], placeholder, ...rest }) => {

  return (
    <AvField {...rest} type="select">
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((opt) => (
        <option key={opt.id} value={opt.id}>
          {opt.name}
        </option>
      ))}
    </AvField>
  );
};

export default AvSelect;
