import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import OrganizedActionsItem from "../components/organized/OrganizedActions";
import img_01 from "../assets/img/img_01.png";
import { request } from "../helperFunctions/requests";
import { correctDate } from "../helperFunctions/correctDate";
import Pagination from '../components/pagination';
import { errorMonitor } from "form-data";

const pageDataCount = 9;

function UpComingNews() {
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);

    useMemo(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [pageNumber]);

    const correctData = useMemo(() => {
        let result = [...data];
        let arr = [];
        result.forEach((item, index) => {
            const pageNumber = Math.ceil((index + 1) / pageDataCount);
            arr.push({
                page: pageNumber,
                data: item
            })
        });
        arr = arr.filter(item => item.page === pageNumber);
        return arr;
    }, [data, pageNumber]);

    const hnadleChangePageNumber = useCallback((id) => {
        setPageNumber(id);
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const abort = { signal: controller.signal };
        try {
            (async () => {
                const { data } = await request("/api/news", {
                    method: "GET",
                });
                setData(data);
            })();
        } catch (err) {
            console.log(err);
        }

        return () => controller.abort();
    }, []);

    return (
        <div className="upComingNews__contant wrapper__cont">
            <Container>
                <Row className="upComingNews__title titleOne">
                    <Col className="titleOne__wrapper">
                        <span>Վերջին նորություններ</span>
                    </Col>
                </Row>
                <Row className="upComingNews__wrapper handle__row25">
                    {correctData.map(({data}) => {
                        return (
                            <Col
                                key={data.id}
                                className="upComingNews__cardsWrapper handle__col25"
                                lg={4}
                                md={12}
                                sm={12}
                            >
                                <Link to={`/upcoming-news/${data.id}`} >
                                    <OrganizedActionsItem
                                        id={data.id}
                                        img={data.image}
                                        date={correctDate(data.date)}
                                        title={data.title}
                                        text={data.description}
                                    />
                                </Link>
                            </Col>
                        );
                    })}
                </Row>
                <Pagination dataCount={data.length} pageData={pageDataCount} pageNumber={pageNumber} hnadleChangePageNumber={hnadleChangePageNumber} />
            </Container>
        </div>
    );
}

export default UpComingNews;
