import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Map from "../../map/map";
import { request } from "../../../helperFunctions/requests";

function Places() {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await request("/api/storage", {
        method: "GET",
      });
      setData(data);
    })();
  }, []);

  return (
    <div className="places__contant wrapper__container">
      <div className="places__up">
        <Container>
          <Row className="places__upWrapper">
            <div className="places__upTitle">
              <span>Պահեստային կետերի ցանկ</span>
            </div>
          </Row>
        </Container>
      </div>
      <div className="places__down">
        <Map data={data} />
      </div>
    </div>
  );
}

export default Places;
