import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const headerMenus = [
    {
        menu: "Ծրագրի մասին",
        arr: [
            // { name: "Մենք այստեղ ենք, որպեսզի օգնենք միմյանց", link: "/sale" },
            { name: "Նպատակ", link: "/goal" },
            { name: "Նկարագիր", link: "/description" },
            {
                name: "Առաջարկվող սոցիալական ծառայություններ",
                link: "/social_workers",
            },
            { name: "Հղումներ", link: "/links" },
        ],
    },
    {
        menu: "Նվիրաբերե’ք հիմա",
        arr: [
            { name: "Պահեստային կետեր", link: "/reserved-points" },
            { name: "Կոնտակտներ", link: "/contacts" },
            { name: "Մեր գործընկերները", link: "/partners" },
            { name: "Նվիրատուներ", link: "/donors" },
        ],
    },
    {
        menu: "Միացե’ք մեզ",
        arr: [
            { name: "Դառնալ կամավոր", link: "/volunteer" },
            { name: "Առաջիկա ակցիաներ", link: "/upcoming-actions/1" },
        ],
    },
    {
        menu: "Նորություններ",
        arr: [
            { name: "Վերջին նորություններ", link: "/upcoming-news" },
            { name: "Կազմակերպվող ակցիաներ", link: "/upcoming-actions/2" },
            { name: "Ամփոփ հաշվետվություն", link: "/reports" },
            { name: "Իրական պատմություններ", link: "/testimonials" },
        ],
    },
];

const footerMenus = [
    {
        name: "Կայքի քարտեզ",
        link: "/sitemap",
    },
    {
        name: "Հղումներ",
        link: "/links",
    },
    {
        name: "Հետադարձ կապ",
        link: "/contacts",
    },
    {
        name: "Հետևեք մեզ ֆեյսբուքում",
        link: "/fb",
    },
];

function AppMap() {
    return (
        <div className="siteMap wrapper__container">
            <Container>
                <Row className="links__title titleOne">
                    <Col className="titleOne__wrapper">
                        <span>Կայքի քարտեզ</span>
                    </Col>
                </Row>
                <Row className="siteMap__body">
                    <Col className="siteMap__item" lg={12}>
                        <p></p>
                    </Col>
                    {headerMenus.map((item) => {
                        return (
                            <>
                                <Col className="siteMap__secondItem" lg={12}>
                                    <div className="siteMap__secondItem-body">
                                        <span>{item.menu}</span>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    {item.arr.map((result) => {
                                        return (
                                            <div className="siteMap__thirdItem-body">
                                                <Link to={result.link}>
                                                    <span>{result.name}</span>
                                                </Link>
                                            </div>
                                        );
                                    })}
                                </Col>
                            </>
                        );
                    })}
                    <Col className="siteMap__item" lg={12}>
                        <p></p>
                    </Col>
                    {footerMenus.map((item) => {
                        return (
                            <Col className="siteMap__secondItem" lg={12}>
                                <div className="siteMap__secondItem-body secondFooterItem">
                                    <Link to={item.link} >
                                        <span>{item.name}</span>
                                    </Link>
                                    <div className="siteMap__secondItem-arrow"></div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </div>
    );
}

export default AppMap;
