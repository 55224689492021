import React from "react";
import { Link } from "react-router-dom";

export default function ArrowButton({
    to,
    mode = "default",
    text,
    disabled,
    ...rest
}) {
    if (!disabled && to) {
        return (
            <div className="arrow__component">
                <Link
                    to={to}
                    className={`arrow__component-body ${mode}`}
                    {...rest}
                >
                    {text}
                </Link>
            </div>
        );
    }
    return (
        <div className="arrow__component">
            <button
                className={`arrow__component-body ${mode}`}
                disabled={disabled}
                {...rest}
            >
                {text}
            </button>
        </div>
    );
}
