import React, { useState, useEffect } from 'react';

import { AvRadioGroup, AvForm, AvRadio } from 'availity-reactstrap-validation';

import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const ModalComponent = ({ isOpen, massage, toggle, borderErr = false}) => {
  return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalBody >
          <div className={`text-center ${borderErr ? 'active' : ''}`}>
            {massage}
          </div>
        </ModalBody>
        {/* <ModalFooter>
          <Button color="primary" type="button" onClick={toggle}>
            փակել
          </Button>
        </ModalFooter> */}
      </Modal>
  );
};

export default ModalComponent;
