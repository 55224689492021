import React, { useEffect, useState, useMemo } from "react";
// import picture_one from "../assets/img/picture_one.png";
import ArrowUp from "../components/arrowUpAndDown/arrowUp";
import { request } from "../helperFunctions/requests";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

function Slider() {
  let location = useLocation();
  const [data, setData] = useState([]);
  const { info: { home_title = "", home_description = "" } = {}, page_title = '', headerMeus: { headerArr = [], footerArr = [] } = {} } = useSelector(
    (state) => state.test
  );

  let match = useMemo(() => {
    return window.location.pathname.substr(1).split("/")[0];
  }, [location]);

  const correctData = useMemo(() => {
    let result = [...data];
    if (!match.length) {
      const index = result.findIndex((item) => item.page === "/");
      if (index !== -1) return { data: result[index], isHome: true };
    } else {
      result = result.filter((item) => item.page === match);
      if (result.length) return { data: result[0], isHome: false };
    }
  }, [data, match]);

  const findSameUrl = useMemo(() => {
    let correctUrl = location.pathname.split('/')[1];
    const arr = [];
    const resultOne = [...headerArr];
    const resultTwo = [...footerArr];
    resultOne.forEach(item => {
      item.arr.forEach(itemTwo => {
        arr.push(itemTwo);
      })
    });
    resultTwo.forEach(item => {
      arr.push(item);
    });
    let result = arr.filter(item => {
      const url = item.link.split('/')[1];
      return url === correctUrl
    });
    return result[0];
  }, [headerArr, footerArr, location]);

  useEffect(() => {
    (async () => {
      const { data } = await request("/api/pages", {
        method: "GET",
      });
      setData(data);
    })();
  }, []);

  if (correctData?.data?.image) {
    return (
      <div
        className={`slider-contant slider__home ${
          correctData?.isHome ? "active" : ""
        }`}
      >
        <div className="slider__text">
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <p>{correctData?.isHome ? home_title : findSameUrl.name}</p>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <span>{correctData?.isHome ? home_description : null}</span>
              </Col>
            </Row>
          </Container>
        </div>
        <img src={correctData?.data?.image} />
        <ArrowUp />
      </div>
    );
  }
  return null;
}

export default Slider;
