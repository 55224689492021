import instance from 'services/api';

export const getTest = () => (dispatch) => {
  return instance.get('/').then(({ data: { data } }) => {
    dispatch({ type: 'TEST', payload: data });
  });
};


export const handleContact = ({name, description, image}) => (dispatch) => {
  const obj = {
    tel: name,
    email: image,
    address: description
  }
  dispatch({type: 'TEL', payload: obj});
};

export const handleMainInfoAndSocialIcons = (data) => (dispatch) => {
  const {info: {data:dataInfo = {}} = {}, socialIcons: {data:dataIcons = {}}} = data;
  dispatch({type: 'INFO_AND_SOCIAL_ICONS', payload: {dataInfo, dataIcons}});
};

export const handleChangePageTitle = (str) => (dispatch) => {
  dispatch({type: 'CHANGE_PAGE_TITLE', payload: str});
};