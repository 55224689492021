import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { request } from "../helperFunctions/requests";
// import { CovidImg, GetHelpsImg } from '../components/Icons';

function Links() {
    const [data, setData] = useState([]);
    useEffect(() => {
        const controller = new AbortController();
        const abort = { signal: controller.signal };
        try {
            (async () => {
                const { data } = await request("/api/links", {
                    method: "GET",
                });
                setData(data);
            })();
        } catch (err) {
            console.log(err);
        }

        return () => controller.abort();
    }, []);

    return (
        <div className="links__contant wrapper__container">
            <Container>
                <Row className="links__title titleOne">
                    <Col className="titleOne__wrapper">
                        <span>Հղումներ</span>
                    </Col>
                </Row>
                <Row className="links__cards linksCards handle__row25">
                    {data.map((item) => {
                        return (
                            <Col
                                key={item.id}
                                className="linksCards__wrapper handle__col25"
                                lg={4}
                                md={6}
                                sm={12}
                            >
                                <a href={item.link} target='_blank'>
                                    <div className="linksCards__body">
                                        <div className="linksCards__icon">
                                            <img src={item.image} alt="" />
                                            {/* <CovidImg className='linksCards__img' /> */}
                                        </div>
                                        <div className="linksCards__titleWrapper">
                                            <div className="linksCards__title">
                                                <p>{item.title}</p>
                                            </div>
                                            <div className="linksCards__email">
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </div>
    );
}

export default Links;
