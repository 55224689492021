import React, { useMemo, useState } from "react";
import { Row, Col } from "reactstrap";

function Pagination({
  dataCount,
  pageData,
  pageNumber,
  hnadleChangePageNumber,
}) {
  const data = useMemo(() => {
    const number = Math.ceil(dataCount / pageData);
    const arr = [];
    for (let i = 0; i < number; i++) {
      arr.push({
        id: i + 1,
        data: i + 1,
      });
    }
    return arr;
  }, [dataCount, pageData]);

  return (
    <Row className="upComingNews__pagination pagination">
      <Col>
        <div className="pagination__wrapper">
          <div className="pagination__body">
            <div
              className="pagination__item pagination__arrow pagination__arrowLeft"
              onClick={() => {
                const result = data.find(item => item.id === pageNumber - 1);
                if (result) hnadleChangePageNumber(pageNumber - 1)
              }}
            ></div>
            {data.map((item) => {
              return (
                <div
                  className={`pagination__item ${
                    pageNumber === item.id ? "active" : ""
                  }`}
                  onClick={() => hnadleChangePageNumber(item.id)}
                >
                  <p>{item.data}</p>
                </div>
              );
            })}
            <div
              className="pagination__item pagination__arrow pagination__arrowRight"
              onClick={() => {
                const result = data.find(item => item.id === pageNumber + 1);
                if (result) hnadleChangePageNumber(pageNumber + 1)
              }}
            ></div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Pagination;
