import React from "react";
import PropTypes from "prop-types";
import { correctText } from "../../helperFunctions/correctText";
import { ArrowButton } from "../common/";
import { Link } from "react-router-dom";

function OrganizedActionsItem({
  img,
  date,
  title,
  text,
  btn,
  id,
  button = false,
}) {
  return (
    <div className="organized-item">
      <div className="organized-item__image">
        <img src={img} alt="image" />
      </div>
      <div className="item-content">
        <div className="item-content-wrapper">
          <div className="item-date">
            <p>{date}</p>
          </div>
          <div className='organized-textInfo' >
            <h5>{title}</h5>
            <div className="organized-item__description">
              <p>{`${correctText(text, 80)}...`}</p>
            </div>
          </div>
          {btn && (
            <div className="item-button">
              <ArrowButton text="Գրանցվել" to />
            </div>
          )}
        </div>
        {button ? (
          <div className="item-content-button">
            <Link to={`/volunteer/${id}`}>
              <div className="headder__down-arrow arrow__component">
                <div className="arrow__component-body">
                  <span>Գրանցվել</span>
                </div>
              </div>
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
}
OrganizedActionsItem.PropTypes = {
  img: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};
OrganizedActionsItem.defaultProps = {
  img: "",
  date: "",
  title: "",
  text: "",
};
export default OrganizedActionsItem;
