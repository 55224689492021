import React, { useCallback, useMemo, useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Preloader from "../components/Preloader";
import instance from "../services/api";
import { LabelRequired, AvSelect, ImageUpload } from "../components/formFields";
import ModalComponent from "components/Modal";

import { Title, ArrowButton } from "../components/common";
import { Years, Month, Days } from "../utils/utils";

function Volunteer() {

  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [massage, setMassage] = useState("");
  const [dates, setDates] = useState(new Date().toJSON().split("T")[0]);
  const [loading, setLoading] = useState(false);
  const year = useMemo(() => Years(), []);
  const month = useMemo(() => Month(), []);
  const days = useMemo(() => Days(), []);
  const [borderErr, setBorderErr] = useState(false);
  const { actions } = useSelector((state) => state.test);

  const description = useMemo(()=>{
    if(actions.length){
      return actions.find(x => x.id === Number(id))?.title || "";
    }
    return ''
  },[id, actions])

  const handleSubmit = useCallback(async (event, value) => {
    setLoading(true);
    const data = {
      name: value.firstname,
      surname: value.surname,
      middlename: value.middlename,
      volunteer_file: value.file,
      birthday: value.day + "-" + value.month + "-" + value.birthdayYear,
      university_name: value.university_name,
      qualification: value.qualification,
      year: value.year,
      description: value.description,
      start_service: value.start_service,
      end_service: value.end_service,
      location: value.location,
      time: value.time,
      action_id: id || "",
    };
    await instance
      .post("/api/volunteer", data)
      .then(({ data }) => {
        setMassage(data.message);
        setIsOpen(true);
        setBorderErr(false);
      })
      .catch(err => {
        setMassage(err);
        setIsOpen(true);
        setBorderErr(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="page-sale wrapper__container">
      <Container>
        <Title title="Դառնալ կամավոր" />
        <div className="volunteer">
          {loading ? (
            <Preloader className="overlay" />
          ) : (
            <AvForm onValidSubmit={handleSubmit} model={{}} key={"form"}>
              <Row>
                <Col sm="8">
                  <Row>
                    <Col sm="4">
                      <AvField
                        required
                        name="firstname"
                        labelClass="label"
                        inputClass="input"
                        label={<LabelRequired>Անուն</LabelRequired>}
                      />
                    </Col>
                    <Col sm="4">
                      <AvField
                        name="surname"
                        // errorMessage={''}
                        className="input"
                        labelClass="label"
                        label={<LabelRequired>Ազգանուն</LabelRequired>}
                        required
                      />
                    </Col>
                    <Col sm="4">
                      <AvField
                        name="middlename"
                        // errorMessage={''}
                        label={<LabelRequired>Հայրանուն</LabelRequired>}
                        className="input"
                        labelClass="label"
                        required
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="4">
                  <Row>
                    <Col sm="4">
                      <AvSelect
                        required
                        name="day"
                        // errorMessage={''}
                        labelClass="label"
                        options={days}
                        inputClass="input"
                        label={<LabelRequired>Ծննդյան</LabelRequired>}
                      />
                    </Col>
                    <Col sm="4">
                      <AvSelect
                        name="month"
                        // errorMessage={''}
                        label={<label></label>}
                        className="input"
                        labelClass="label"
                        options={month}
                        required
                      />
                    </Col>
                    <Col sm="4">
                      <AvSelect
                        name="birthdayYear"
                        label={<label></label>}
                        options={year}
                        className="input"
                        labelClass="label"
                        required
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <AvField
                    name="university_name"
                    labelClass="label"
                    className="input"
                    label={<LabelRequired>ԲՈՒՀ-ի անվանումը</LabelRequired>}
                    required
                  />
                </Col>
                <Col sm="4">
                  <AvField
                    name="qualification"
                    labelClass="label"
                    className="input"
                    label={<LabelRequired>Որակավորում</LabelRequired>}
                    required
                  />
                </Col>
                <Col sm="4">
                  <AvSelect
                    label={<LabelRequired>Տարեթիվ</LabelRequired>}
                    className="text-capitalize input"
                    labelClass="label"
                    name="year"
                    options={year}
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <AvField
                    name="address"
                    // errorMessage={''}
                    label={<LabelRequired>Հասցե</LabelRequired>}
                    className="input"
                    labelClass="label"
                    required
                  />
                </Col>
                <Col sm="6">
                  <AvField
                    name="phone"
                    // errorMessage={''}
                    validate={{
                      tel: {
                        pattern: /^(\+374|0)\d{8}$/,
                      },
                    }}
                    type="tel"
                    label={<LabelRequired>Հեռախոս</LabelRequired>}
                    className="input"
                    labelClass="label"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <ImageUpload className="lg" url="volunteer" name="file" />
                </Col>
                <Col sm="6">
                  <AvField
                    name="description"
                    labelClass="label"
                    value={description}
                    className="input"
                    label={<LabelRequired>Նկարագիր</LabelRequired>}
                    type="textarea"
                    rows="4"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <AvField
                    name="start_service"
                    // errorMessage={''}
                    label={<LabelRequired>Ծառայության սկիզբ</LabelRequired>}
                    onChange={(e) => setDates(e.target.value)}
                    type="date"
                    className="input"
                    labelClass="label"
                    required
                  />
                </Col>
                <Col sm="6">
                  <AvField
                    name="end_service"
                    type="date"
                    min={dates}
                    // errorMessage={''}
                    label={<LabelRequired>Ծառայության ավարտ</LabelRequired>}
                    className="input"
                    labelClass="label"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <AvField
                    name="location"
                    // errorMessage={''}
                    label={<LabelRequired>Վայրը</LabelRequired>}
                    className="input"
                    labelClass="label"
                    required
                  />
                </Col>
                <Col sm="6">
                  <AvField
                    name="time"
                    // errorMessage={''}
                    label={<LabelRequired>Ժամաքանակը</LabelRequired>}
                    type="number"
                    className="input"
                    labelClass="label"
                    required
                  />
                </Col>
              </Row>
              <div className="form-footer">
                <ArrowButton text="Դառնալ կամավոր" />
              </div>
            </AvForm>
          )}
        </div>
        <ModalComponent
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          massage={massage}
          borderErr={borderErr}
        />
      </Container>
    </div>
  );
}

export default Volunteer;
