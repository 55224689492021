import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

const instance = axios.create({
  baseURL: 'https://admin.soc-bank.am',
});
instance.interceptors.response.use(
  (response) => {
    // if (response.config.method !== 'get' && response?.data?.message) {
    //   toast.success(response.data.message);
    // }
    return response;
  },
  (error) => {
    let message;
    if (error.config.method !== 'get' && error.response?.data?.message) {
      message = error.response.data.message;
      console.log('====================================');
      console.log(error.response.data);
      console.log('====================================');
      if (error.response.data.errors) {

        message += `\n ${Object.values(error.response.data.errors)
          .map(({ msg }) => msg)
          .join(`\n`)}`;
      }
      // toast.error(message);
    }
    return Promise.reject(message);
  }
);

export default instance;
