const footerArr = [
  {
    name: "Կայքի քարտեզ",
    link: "/sitemap",
  },
  {
    name: "Հղումներ",
    link: "/links",
  },
  {
    name: "Հետադարձ կապ",
    link: "/contacts",
  },
];

const headerArr = [
  {
    menu: "Ծրագրի մասին",
    arr: [
      { name: "Նպատակ", link: "/goal" },
      { name: "Նկարագիր", link: "/description" },
      {
        name: "Առաջարկվող սոցիալական ծառայություններ",
        link: "/social_workers",
      },
      { name: "Հղումներ", link: "/links" },
    ],
  },
  {
    menu: "Նվիրաբերեք հիմա",
    arr: [
      { name: "Նվիրաբերել", link: "/donate" },
      { name: "Պահեստային կետեր", link: "/reserved-points" },
      { name: "Կոնտակտներ", link: "/contacts" },
      { name: "Մեր գործընկերները", link: "/partners" },
      { name: "Նվիրատուներ", link: "/donors" },
    ],
  },
  {
    menu: "Միացեք մեզ",
    arr: [
      { name: "Դառնալ կամավոր", link: "/volunteer" },
      { name: "Առաջիկա ակցիաներ", link: "/upcoming-actions/1" },
    ],
  },
  {
    menu: "Նորություններ",
    arr: [
      { name: "Վերջին նորություններ", link: "/upcoming-news" },
      { name: "Կազմակերպվող ակցիաներ", link: "/upcoming-actions/2" },
      { name: "Ամփոփ հաշվետվություն", link: "/reports" },
      { name: "Իրական պատմություններ", link: "/testimonials" },
    ],
  },
];

const initialState = {
  data: [],
  contact: {
    tel: "",
    email: "",
    address: "",
  },
  info: {
    home_description: "",
    home_foundation: "",
    home_prepared: "",
    home_title: "",
    our_works_description: "",
    home_body_description: "",
    home_body_title: "",
  },
  social_icons: [],
  page_title: '',
  actions: [],
  headerMeus: { headerArr, footerArr },
};

const test = (state = initialState, { type, payload }) => {
  switch (type) {
    case "TEST":
      return {
        data: payload,
      };
    case "TEL":
      return {
        ...state,
        contact: payload,
      };
    case "INFO_AND_SOCIAL_ICONS":
      const { dataInfo, dataIcons } = payload;
      return {
        ...state,
        info: dataInfo,
        social_icons: dataIcons,
      };
    case "CHANGE_PAGE_TITLE":
      return {
        ...state,
        page_title: payload,
      };
    case "SET_ACTIONS":
    return {
      ...state,
      actions: payload
    }
    default:
      return state;
  }
};

export default test;
