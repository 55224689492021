import React from "react";
import ReactLoading from "react-loading";

const Preloader = ({ className }) => {
  return (
    <div className={`preloader ${className}`}>
      {/* <div className="loader_block">
        <div className="dot loader_dot_1"></div>
        <div className="dot loader_dot_2"></div>
        <div className="dot loader_dot_3"></div>
      </div> */}
      <ReactLoading type={"spin"} color="#7bc25a" />
    </div>
  );
};

export default Preloader;
