import React from 'react';
import {Row, Col} from 'reactstrap';
import PropTypes from 'prop-types';

function Title({ title, color }) {
  return (
    // <div className="page-title">
    //   <h1>{title}</h1>
    // </div>
    <Row className={`titleOne ${!color ? '' : 'yellow'}`}>
      <Col className="titleOne__wrapper">
        <span>{title}</span>
      </Col>
    </Row>
  );
}
Title.prototype = {
  title: PropTypes.string.isRequired,
};
Title.defaultProps = {
  title: '',
  color: '',
};
export default Title;
