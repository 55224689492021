import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import pdf from "../assets/img/icons_18.png";
import { request } from "../helperFunctions/requests";
import Pagination from "../components/pagination";
// import { Pdf } from '../components/Icons';

const pageDataCount = 20;

function Reports() {
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const controller = new AbortController();
    const abort = { signal: controller.signal };
    try {
      (async () => {
        const { data } = await request("/api/reports", {
          method: "GET",
        });
        setData(data);
      })();
    } catch (err) {
      console.log(err);
    }

    return () => controller.abort();
  }, []);

  useMemo(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pageNumber]);

  const correctData = useMemo(() => {
    let result = [...data];
    let arr = [];
    result.forEach((item, index) => {
      const pageNumber = Math.ceil((index + 1) / pageDataCount);
      arr.push({
        page: pageNumber,
        data: item,
      });
    });
    arr = arr.filter((item) => item.page === pageNumber);
    return arr;
  }, [data, pageNumber]);

  const hnadleChangePageNumber = useCallback((id) => {
    setPageNumber(id);
  }, []);

  return (
    <div className="reports wrapper__container">
      <Container>
        <Row className="reports__title titleOne">
          <Col className="titleOne__wrapper">
            <span>Ամփոփ հաշվետվություն</span>
          </Col>
        </Row>
        <Row>
          {correctData.map(({data:item}) => {
            return (
              <Col key={item.id} lg={12} className="report">
                <a href={item.file} target="_blank">
                  <div className="reports__item report__wrapper">
                    <p className="report__title">{item.title}</p>
                    <div className="report__imgWrapper">
                      <img src={pdf} alt="" />
                      {/* <Pdf className='report__img' /> */}
                    </div>
                    <div className="report__animation"></div>
                  </div>
                </a>
              </Col>
            );
          })}
        </Row>
        <Pagination
          dataCount={data.length}
          pageData={pageDataCount}
          pageNumber={pageNumber}
          hnadleChangePageNumber={hnadleChangePageNumber}
        />
      </Container>
    </div>
  );
}

export default Reports;
