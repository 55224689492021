import React from "react";
import GoogleMapReact from "google-map-react";

function Marker({ state = "", address = "" }) {
    return (
        <div className="marker__relative">
            <div className="marker__absolute">
                <div className="marker__absolute-afterAndBefore">
                    <div className="marker__absolute-before">
                        <p>
                            <span>Համայնք՝</span> {state}
                        </p>
                        <p>
                            <span>Հասցե՝</span> {address}
                        </p>
                    </div>
                    <div className="marker__absolute-after"></div>
                </div>
            </div>
        </div>
    );
}

function Map({ data = [] }) {
    return (
        <div className="reservedPoints__contant-map">
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: "AIzaSyBAkaAKUEQP9jtlhRqnpN8-4-wIWsyzwMM",
                }}
                defaultCenter={{ lat: 40.1772, lng: 44.50349 }}
                defaultZoom={8}
            >
                {data.map((item) => {
                    const {
                        street={},
                        latitude = "",
                        longitude = "",
                        id,
                        region = {}
                    } = item;
                    return (
                        <Marker
                            key={id}
                            lat={+latitude}
                            lng={+longitude}
                            state={region?.region_name}
                            address={street?.name}
                        />
                    );
                })}
            </GoogleMapReact>
        </div>
    );
}

export default Map;
