import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./components/Routes";
import Footer from "./components/footer";
import Header from "./components/header";
import Call from "./components/Call";
import { useDispatch } from "react-redux";
import { request } from "./helperFunctions/requests";
import { handleMainInfoAndSocialIcons } from "./actions/test";

function App() {
  const dispatch = useDispatch();
  const [dataSocial, setDataSocial] = useState([]);
  useEffect(() => {
    (async () => {
      const { data } = await request(`/api/info`, {
        method: "GET",
      });
      await Promise.all([
        request(`/api/info`, {
          method: "GET",
        }),
        request(`/api/social/networks`, {
          method: "GET",
        }),
      ])
      .then(([info, socialIcons]) => dispatch(handleMainInfoAndSocialIcons({info, socialIcons})))
    })();
  }, []);

  return (
    <main className="app-main">
      <div className="app-content">
        <Router>
          <Header />
          <Routes />
          <Footer />
          <Call />
        </Router>
      </div>
    </main>
  );
}

export default App;
