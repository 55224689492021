import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import googleImg from "../assets/img/googleImg.png";
import { request } from "../helperFunctions/requests";
import {Link} from 'react-router-dom';

function Partners() {
    const [data, setData] = useState([]);
    useEffect(() => {
        const controller = new AbortController();
        const abort = { signal: controller.signal };
        try {
            (async () => {
                const { data } = await request("/api/partners", {
                    method: "GET",
                });
                setData(data);
            })();
        } catch (err) {
            console.log(err);
        }

        return () => controller.abort();
    }, []);

    console.log(data, 'data');

    return (
        <div className="partnersPage__contant wrapper__container">
            <Container>
                <Row className="partnersPage__title titleOne">
                    <Col className="titleOne__wrapper">
                        <span>Մեր գործընկերները</span>
                    </Col>
                </Row>
                <Row className="partnersPage__body handle__row15">
                    {data.map((item) => {
                        return (
                            <Col
                                className="partnersPage__item handle__col15"
                                lg={3}
                                md={6}
                                sm={12}
                            >
                                <a className={`${!item.link ? 'partnersPage__event' : ''}`} href={item.link} target='_blank' >
                                    <div className={`partnersPage__itemWrapper ${item.link ? 'isLink' : ''}`}>
                                        <div className="partnersPage__img">
                                            <img src={item.image} alt="" />
                                        </div>
                                    </div>
                                </a>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </div>
    );
}

export default Partners;
