import moment from "moment";

export const correctDate = (str, bool = false) => {
    if (!str) return;
    let result = '';
    if (bool) {
        result = moment(str).format("DD MM YYYY").split(" ");
    }
    result = str.split("-");
    let month = "";
    switch (result[1]) {
        case "01":
            month = "հունվար";
            break;
        case "02":
            month = "փետրվար";
            break;
        case "03":
            month = "մարտ";
            break;
        case "04":
            month = "ապրիլ";
            break;
        case "05":
            month = "մայիս";
            break;
        case "06":
            month = "հունիս";
            break;
        case "07":
            month = "հուլիս";
            break;
        case "08":
            month = "օգոստոս";
            break;
        case "09":
            month = "սեպտեմբեր";
            break;
        case "10":
            month = "հոկտեմբեր";
            break;
        case "11":
            month = "նոյեմբեր";
            break;
        default:
            month = "դեկտեմբեր";
    }
    return `${result[0]} ${month} ${result[2]}`;
};
