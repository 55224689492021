import React from "react";
import { correctDate } from "../helperFunctions/correctDate";
import { Container, Row, Col } from "reactstrap";
import { ArrowButton } from "./common/";
import { Link } from "react-router-dom";

function SinglePage({
  title = "",
  image = "",
  date = "",
  description = "",
  id,
  type = false,
  className = "",
}) {
  const btn = (
    <Link to={`/volunteer/${id}`}>
      <div className="item-button">
        <ArrowButton text="Գրանցվել"/>
      </div>
    </Link>
  );
  return (
    <div className={`singleNews wrapper__container ${className}`}>
      <Container>
        <Row className="reports__title titleOne">
          <Col className="titleOne__wrapper">
            <span>{title}</span>
          </Col>
        </Row>
        <Row>
          <Col lg={5}>
            <div className="singleNews__img">
              <img src={image} alt="" />
            </div>
            {type === "1" ? btn : null}
          </Col>
          <Col lg={7}>
            <div className="singleNews__body">
              <div className="singleNews__date">{correctDate(date)}</div>
              <div className="singleNews__text">
                <p
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SinglePage;
