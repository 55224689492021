import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Container } from "reactstrap";
import { Title } from "../components/common";
import DonorItem from "../components/donors/donorItem";
import { request } from "../helperFunctions/requests";
import Pagination from '../components/pagination';

const pageDataCount = 20;

function Donors() {
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  useMemo(() => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}, [pageNumber]);

const correctData = useMemo(() => {
    let result = [...data];
    let arr = [];
    result.forEach((item, index) => {
        const pageNumber = Math.ceil((index + 1) / pageDataCount);
        arr.push({
            page: pageNumber,
            data: item
        })
    });
    arr = arr.filter(item => item.page === pageNumber);
    return arr;
}, [data, pageNumber]);

const hnadleChangePageNumber = useCallback((id) => {
    setPageNumber(id);
}, []);

  useEffect(() => {
    const controller = new AbortController();
    const abort = { signal: controller.signal };

    try {
      (async () => {
        const { data } = await request("/api/warehouse/items", {
          method: "GET",
        });
        setData(data);
      })();
    } catch (err) {
      console.log(err);
    }

    return () => controller.abort();
  }, []);

  return (
    <div className="donors-page wrapper__container">
      <Container>
        <Title title="Նվիրատուներ" />
        <div className="donors-content">
          {correctData.map(({data: item}) => {
            let point = item.count
              ? `${item.count} հատ`
              : item.weight_kg
              ? `${item.weight_kg} կգ`
              : item.weight_l
              ? `${item.weight_l} լ`
              : null;
            if (point)
              return (
                <DonorItem
                  key={item.id}
                  name={item.name}
                  point={point}
                  title={item.title}
                />
              );
          })}
        </div>
        <Pagination
          dataCount={data.length}
          pageData={pageDataCount}
          pageNumber={pageNumber}
          hnadleChangePageNumber={hnadleChangePageNumber}
        />
      </Container>
    </div>
  );
}
export default Donors;
