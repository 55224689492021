import React, { useCallback, useState, useEffect } from "react";
import { Nav, Row, Col, NavLink, NavItem, Container } from "reactstrap";
import instance from "../services/api";
import Preloader from "../components/Preloader";
import ModalComponent from "components/Modal";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { LabelRequired, ImageUpload, Label } from "../components/formFields";
import { Title, ArrowButton } from "../components/common";

function Donate() {

  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [massage, setMassage] = useState("");
  const [type, setType] = useState("phisical");
  const [borderErr, setBorderErr] = useState(false);
  // const [emptyInputs, setEmptyInputs] = useState(false);

  // useEffect(() => {
  //   let timoutId;
  //   if (emptyInputs) {
  //     setType(type === "legal" ? "phisical" : "legal");
  //     timoutId = setTimeout(() => setType(type === "legal" ? "phisical" : "legal"), 100);
  //   }
  //   return () => clearTimeout(timoutId);
  // }, [emptyInputs]);

  const handleSubmit = useCallback(
    async (event, value) => {
      setLoading(true);
      await instance
        .post("/api/donator?", { ...value, type })
        .then(({ data }) => {
          setMassage(data.message);
          setIsOpen(true);
          setBorderErr(false);
          setKey(key+1)
          // setEmptyInputs(true);
        }).catch(err => {
          setMassage(err);
          setIsOpen(true);
          setBorderErr(true);
          // setEmptyInputs(false);
        })
        .finally(() => {
          setLoading(false);
          // setEmptyInputs(false);
        });
    },
    [type]
  );

  return (
    <div className="page-sale wrapper__container">
      <Container>
        <Title title="Նվիրատվություն" />
        <div className="donate">
          {loading && <Preloader className="overlay" />}
          <Nav className="donate-nav">
            <NavItem>
              <NavLink
                className={type === "legal" ? "active" : ""}
                onClick={() => {
                  setType("legal");
                  setKey(key+1)
                }}
              >
                Իրավաբանական անձ
                
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={type === "phisical" ? "active" : ""}
                onClick={() => {
                  setType("phisical");
                  setKey(key+1)
                }}
              >
                Ֆիզիկական անձ
              </NavLink>
            </NavItem>
          </Nav>
          <AvForm onValidSubmit={handleSubmit} key={key}>
            {type !== "phisical" ? (
              <>
                <Row>
                  <Col sm="12">
                    <AvField
                      name="company_type"
                      labelClass="label"
                      className="input"
                      autocomplete="none"
                      required
                      label={
                        <LabelRequired>Կազմակերպության անվանում</LabelRequired>
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <AvField
                      name="company_name"
                      autocomplete="none"
                      labelClass="label"
                      className="input"
                      required
                      label={<LabelRequired> Անուն</LabelRequired>}
                    />
                  </Col>
                  <Col sm="6">
                    <AvField
                      name="contact_person"
                      autocomplete="none"
                      labelClass="label"
                      className="input"
                      label={<LabelRequired>Ազգանուն</LabelRequired>}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <AvField
                      name="company_hvhh"
                      // errorMessage={''}
                      autocomplete="none"
                      label={
                        <LabelRequired>Կազմակերպության ՀՎՀՀ</LabelRequired>
                      }
                      type="number"
                      className="input"
                      labelClass="label"
                      required
                    />
                  </Col>
                  <Col sm="6">
                    <AvField
                      type="number"
                      name="state_register_code"
                      // errorMessage={''}
                      autocomplete="none"
                      label={<LabelRequired>Պետռեգիստրի կոդ</LabelRequired>}
                      className="input"
                      labelClass="label"
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <AvField
                      name="legal_address"
                      // errorMessage={''}
                      autocomplete="none"
                      label={<LabelRequired>Իրավաբանական հասցե</LabelRequired>}
                      className="input"
                      labelClass="label"
                      required
                    />
                  </Col>
                  <Col sm="6">
                    <AvField
                      name="activity_address"
                      autocomplete="none"
                      // errorMessage={''}
                      label={
                        <LabelRequired>Գործունեության հասցե</LabelRequired>
                      }
                      className="input"
                      labelClass="label"
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <AvField
                      name="email"
                      type="email"
                      autocomplete="none"
                      // errorMessage={''}
                      label={<LabelRequired>Էլ․ հասցե</LabelRequired>}
                      className="input"
                      labelClass="label"
                      required
                    />
                  </Col>
                  <Col sm="6">
                    <AvField
                      name="phone"
                      // errorMessage={''}
                      type="tel"
                      autocomplete="none"
                      label={<LabelRequired>Հեռախոս</LabelRequired>}
                      className="input"
                      labelClass="label"
                      validate={{
                        tel: {
                          pattern: /^(\+374|0)\d{8}$/,
                        },
                      }}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <AvField
                      name="description"
                      labelClass="label"
                      className="input"
                      autocomplete="none"
                      label={<LabelRequired>Նկարագիր</LabelRequired>}
                      type="textarea"
                      rows="4"
                      required
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col sm={3}>
                    <AvField
                      name="contact_person"
                      labelClass="label"
                      className="input"
                      label={<LabelRequired>Անուն</LabelRequired>}
                      required
                    />
                  </Col>
                  <Col sm={3}>
                    <AvField
                      name="contact_person_lastName"
                      labelClass="label"
                      className="input"
                      label={<LabelRequired>Ազգանուն</LabelRequired>}
                      required
                    />
                  </Col>
                  <Col sm={6}>
                    <AvField
                      name="physical_person_email"
                      type="email"
                      autocomplete="none"
                      labelClass="label"
                      className="input"
                      label={<LabelRequired>Էլ․ հասցե</LabelRequired>}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <ImageUpload
                      className="lg"
                      url="donator"
                      name="physical_person_document"
                    />
                  </Col>
                  <Col sm={6}>
                    <AvField
                      name="physical_person_phone"
                      labelClass="label"
                      className="input"
                      autocomplete="none"
                      validate={{
                        tel: {
                          pattern: /^(\+374|0)\d{8}$/,
                        },
                      }}
                      type="tel"
                      label={<LabelRequired>Հեռախոս</LabelRequired>}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <AvField
                      name="description"
                      labelClass="label"
                      className="input"
                      autocomplete="none"
                      label={<LabelRequired>Նկարագիր</LabelRequired>}
                      type="textarea"
                      rows="4"
                      required
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col xs={12}>
                <AvField
                  name="incognito"
                  labelClass="label"
                  // className="input"
                  label={<label>Ցանկանում եմ մնալ անանուն</label>}
                  type="checkbox"
                  rows="4"
                  // requireds
                />
              </Col>
            </Row>
            <div className="form-footer">
              <ArrowButton text="Ինչ եք պատրաստվում նվիրաբերել" />
            </div>
          </AvForm>
        </div>
      </Container>
      <ModalComponent
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
        massage={massage}
        borderErr = {borderErr}
      />
    </div>
  );
}

export default Donate;
