import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import icons_18 from "../../../assets/img/icons_18.png";
import { request } from "../../../helperFunctions/requests";
import { Pdf } from '../../Icons';

function PaperInfo() {
  const [data, setData] = useState([]);

  const correctData = useMemo(() => {
    let result = [...data];
    return result.splice(0, 6);
  }, [data]);

  useEffect(() => {
    (async () => {
      const { data } = await request("/api/reports", {
        method: "GET",
      });
      setData(data);
    })();
  }, []);

  return (
    <div className="paperInfo__contant wrapper__container">
      <Container>
        <Row className="paperInfo__title titleOne">
          <Col className="titleOne__wrapper">
            <span>Ամփոփ հաշվետվություն</span>
          </Col>
        </Row>
        <Row className="paperInfo__bodyWrapper">
          <Col className="paperInfo__body">
            <Row className="paperInfo__items handle__row25">
              {correctData.map((item) => {
                return (
                  <Col
                    key={item.id}
                    className="paperInfo__item pdfItem handle__col25"
                    lg={4}
                  >
                    <a href={item.file} target="_blank">
                      <div className="pdfItem__wrapper">
                        <div className="pdfItem__body">
                          <div className="pdfItem__img">
                            {/* <img src={icons_18} alt="" /> */}
                            <Pdf className='pdfItem__image' />
                          </div>
                          <div className="pdfItem__title">
                            <span>{item.title}</span>
                          </div>
                        </div>
                        <div className="pdfItem__line"></div>
                      </div>
                    </a>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row className="paperInfo__linkWrapper">
          <Col className="paperInfo__link">
            <Link to="/reports">
              <span>Տեսնել բոլորը</span>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PaperInfo;
