import React, { useEffect, useState } from "react";
import {
  ToGiveHelp,
  FreeFamily,
  FreeFamilyToHelp,
  Gifts,
  FreePeople,
  ToGetHelps,
} from "../../Icons";
import icons_15 from "../../../assets/img/icons_15.png";
import icons_17 from "../../../assets/img/icons_17.png";
import icons_16 from "../../../assets/img/icons_16.png";
import icons_09 from "../../../assets/img/icons_09.png";
import icons_10 from "../../../assets/img/icons_10.png";
import icons_11 from "../../../assets/img/icons_11.png";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { request } from "../../../helperFunctions/requests";
import { useSelector } from "react-redux";

function OurWorks() {
  const [data, setData] = useState([]);
  const { info: { our_works_description = "" } = {} } = useSelector(
    (state) => state.test
  );

  useEffect(() => {
    (async () => {
      const { data } = await request("/api/our/works", {
        method: "GET",
      });
      setData(data);
    })();
  }, []);

  return (
    <div className="ourWorks__contant wrapper__container">
      <div className="ourWorks__helps">
        <Container className='ourWork__origin-container' >
          <Row>
            <Col>
              <div className="ourWorks__container">
                <div className="ourWorks__helpWrapper handle__row">
                  <div className="ourWorks__helpBody handle__col">
                    <Link to="/donate">
                      <div className="ourWorks__help help">
                        <div className="help__body">
                          <div className="help__img">
                            {/* <img src={icons_09} alt="" /> */}
                            <Gifts />
                          </div>
                          <div className="help__text">
                            <span>Նվիրաբերել</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="ourWorks__helpBody handle__col">
                    <Link to="/volunteer">
                      <div className="ourWorks__help active help">
                        <div className="help__body">
                          <div className="help__img">
                            {/* <img src={icons_10} alt="" /> */}
                            <FreePeople />
                          </div>
                          <div className="help__text active">
                            <span>Դառնալ կամավոր</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="ourWorks__helpBody handle__col">
                    <Link to="/social_workers">
                      <div className="ourWorks__help help">
                        <div className="help__body">
                          <div className="help__img">
                            {/* <img src={icons_11} alt="" /> */}
                            <ToGetHelps />
                          </div>
                          <div className="help__text">
                            <span>Ստանալ օգնություն</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="ourWorks__wrapper">
          <Row className="ourWorks__titleWrapper">
            <div className="ourWorks__title">
              <span>Մեր աշխատանքները</span>
            </div>
          </Row>
          <Row className="ourWorks__text">
            <span>{our_works_description}</span>
          </Row>
          <Row className="ourWorks__infoWrapper handle__row">
            {data.map((item) => {
              return (
                <Col
                  lg={4}
                  md={12}
                  sm={12}
                  key={item.id}
                  className="ourWorks__infoBody handle__col"
                >
                  <div className="ourWorks__info info">
                    <div className="info__img">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="info__count">
                      <span>{item.total}</span>
                    </div>
                    <div className="info__title">
                      <span>{item.title}</span>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default OurWorks;
