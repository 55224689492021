import React, { useMemo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { request } from "../helperFunctions/requests";
import SinglePage from "../components/singlePage";

function SingleAkcia() {
  const { id, type } = useParams();
  const [data, setData] = useState([]);

  const correctData = useMemo(() => {
    let result = [...data];
    result = result.filter((item) => item.id === +id);
    if (result.length) return result[0];
    return {};
  }, [data, id]);

  useEffect(() => {
    const controller = new AbortController();
    const abort = { signal: controller.signal };
    try {
      (async () => {
        const { data } = await request(
          `/api/action/${type === "1" ? "upcoming" : "organized"}`,
          {
            method: "GET",
          }
        );
        setData(data);
      })();
    } catch (err) {
      console.log(err);
    }

    return () => controller.abort();
  }, [type]);

  return (
    <SinglePage
      title={correctData.title}
      image={correctData.image}
      date={correctData.date}
      description={correctData.description}
      id={id}
      type={type}
      className='upComingAkcia__singlePage'
    />
  );
}

export default SingleAkcia;
