import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import { SocialWorkerImg } from "../components/Icons";
import { request } from "../helperFunctions/requests";
import {Link} from 'react-router-dom';

function SocialWorkers() {
  const [data, setData] = useState([]);
  const [typeId, setTypeId] = useState(0);

  const correctData = useMemo(() => {
    let result = data.filter((item) => item.id === typeId);
    if (!result.length) {
      return [];
    }
    return result[0].items;
  }, [data, typeId]);

  useEffect(() => {
    (async () => {
      const { data } = await request("/api/social/worker", {
        method: "GET",
      });
      setData(data);
      setTypeId(data[0].id);
    })();
  }, []);

  return (
    <div className="socialWorker__contant wrapper__container">
      <Container>
        <Row className="socialWorker__title titleOne yellow">
          <Col className="titleOne__wrapper">
            <span>Առաջարկվող սոցիալական ծառայություններ</span>
          </Col>
        </Row>
        <Row className="socialWorker__menusType">
          <Col className="socialWorker__menu" lg={1}>
            <div className="socialWorker__menuWrapper">
              {data.map((item) => {
                return (
                  <p
                    key={item.id}
                    className={`${typeId === item?.id ? "active" : ""}`}
                    onClick={() => setTypeId(item.id)}
                  >
                    <span>{item.title}</span>
                  </p>
                );
              })}
            </div>
          </Col>
        </Row>
        <Row className="paperInfo__bodyWrapper">
          <Col className="paperInfo__body">
            <Row className="paperInfo__items handle__row25">
              {correctData.map((item) => {
                return (
                  <Col
                    key={item.id}
                    className="paperInfo__item pdfItem active noPadding handle__col25"
                    lg={4}
                  >
                    {/* <Link to={'/volunteer'} > */}
                      <div className="pdfItem__wrapper">
                        <div className="pdfItem__body">
                          <div className="pdfItem__img">
                            {/* <SocialWorkerImg className="pdfItem__image" /> */}
                            <img src={item.file} alt="" />
                          </div>
                          <div className="pdfItem__title">
                            <span>{item.title}</span>
                          </div>
                        </div>
                        <div className="pdfItem__line"></div>
                      </div>
                    {/* </Link> */}
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SocialWorkers;
