import React from 'react';
import picture_one from '../../assets/img/picture_one.png';

function Slider() {
    return (
        <div className="slider-contant" >
            <img src={picture_one} alt="Picture"/>
        </div>
    )
};

export default Slider;