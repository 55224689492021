import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { request } from "../../../helperFunctions/requests";
import Carousel from "react-elastic-carousel";

function Partners() {
  const [data, setData] = useState([]);
  const [screen, setScreen] = useState(1028);

  useEffect(() => {
    (async () => {
      const { data } = await request("/api/partners", {
        method: "GET",
      });
      setData(data);
    })();
  }, []);

  return (
    <div className="partners__contant wrapper__container">
      <Container className="partners__wrapper">
        <Row className="partners__title titleOne">
          <Col className="titleOne__wrapper">
            <span>Գործընկերներ</span>
          </Col>
        </Row>
        <Row className="partners__wrapper">
          <Col className="partners__body">
            <div className="partners__bodyWrapper">
              <Carousel 
              itemsToShow={5} 
              pagination={false}
              breakPoints={[{'width': '500', 'itemsToShow': '1'}, {'width': '768', 'itemsToShow': '2'}, {'width': '992', 'itemsToShow': '3'}, {'width': '1200', 'itemsToShow': '5'}]}
              >
                {data.map((item) => {
                  return (
                    <a href={item.link} target="_blank">
                      <div className="partners__item">
                        <img src={item.image} alt="" />
                      </div>
                    </a>
                  );
                })}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Partners;
