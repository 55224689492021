import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import holmes from "../assets/img/holmes.jpg";
import { request } from "../helperFunctions/requests";
import icon from '../assets/img/icons-25.png';
// import { Comma } from '../components/Icons';

function Testimonials() {

  const [data, setData] = useState([]);
  
    useEffect(() => {
        const controller = new AbortController();
        const abort = { signal: controller.signal };
        try {
            (async () => {
                const { data } = await request("/api/testimonials", {
                    method: "GET",
                });
                setData(data);
            })();
        } catch (err) {
            console.log(err);
        }

        return () => controller.abort();
    }, []);

    return (
        <div className="testimonials__contant wrapper__container">
            <Container>
                <Row className="testimonials__title titleOne">
                    <Col className="titleOne__wrapper">
                        <span>Իրական պատմություններ</span>
                    </Col>
                </Row>
                <Row className="testimonials__infoItem testimonials__info">
                    {data.map((item) => {
                        return (
                            <Col key={item.id} className="testimonials__info-item" lg={12} md={12} sm={12}>
                                <div className="testimonials__info-wrapper">
                                    <div className="testimonials__info-body">
                                        <div className="testimonials__info-imgPart">
                                            <div>
                                                <img src={item.image} alt="" />
                                            </div>
                                        </div>
                                        <div className="testimonials__info-Part">
                                            <div className="testimonials__info-imgErapper">
                                                {/* <Comma className="testimonials__info-img" /> */}
                                                <img src={icon} alt='' />
                                            </div>
                                            <div className="testimonials__info-textWrapper">
                                                <div className="testimonials__info-text">
                                                    <p>{item.description}</p>
                                                </div>
                                                <div className="testimonials__info-names">
                                                    <p>{item.name}</p>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </div>
    );
}

export default Testimonials;
