import React from "react";
import { Route, Switch } from "react-router-dom";
import WrapperScroll from "./hoc/wrapper_scrol";

import OrganizedActions from "../pages/organizedActions";
import UpcomingActions from "../pages/upcomingActions";
import PageNotFound from "./error/PageNotFound";
import SocialWorkers from "../pages/socialWorkers";
import Testimonials from "../pages/testimonials";
import Description from "../pages/description";
import Volunteer from "../pages/volunteer";
import Partners from "../pages/partners";
import Home from "../pages/homepage";
import Donate from "../pages/donate";
import Donors from "../pages/donors";
import Links from "../pages/links";
import Sale from "../pages/sale";
import Goal from "../pages/goal";
import UpComingNews from "../pages/upComingNews";
import UpComingAkcia from "../pages/upComingAkcia";
import ReservedPoints from "../pages/reservedPoints";
import Reports from "../pages/reports";
import Contacts from "../pages/contacts";
import SingleNews from "../pages/newsSingle";
import AppMap from "../pages/appMap";
import SingleAkcia from '../pages/singleAkcia'

function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <WrapperScroll Component={Home} />
      </Route>
      <Route path="/sale">
        <WrapperScroll Component={Sale} />
      </Route>
      <Route path="/donate">
        <WrapperScroll Component={Donate} />
      </Route>
      <Route path="/donors">
        <WrapperScroll Component={Donors} />
      </Route>
      {/* <Route path="/:organized-actions">
                <OrganizedActions />
            </Route> */}
      <Route exact path="/upcoming-actions/:type">
        <WrapperScroll Component={UpComingAkcia} />
      </Route>
      <Route exact path="/upcoming-actions/:type/:id">
        <WrapperScroll Component={SingleAkcia} />
      </Route>
      <Route path="/goal">
        <WrapperScroll Component={Goal} />
      </Route>
      <Route path="/description">
        <WrapperScroll Component={Description} />
      </Route>
      <Route path="/social_workers">
        <WrapperScroll Component={SocialWorkers} />
      </Route>
      <Route path="/links">
        <WrapperScroll Component={Links} />
      </Route>
      <Route path="/partners">
        <WrapperScroll Component={Partners} />
      </Route>
      <Route path="/testimonials">
        <WrapperScroll Component={Testimonials} />
      </Route>
      <Route path="/volunteer/:id?">
        <WrapperScroll Component={Volunteer} />
      </Route>
      <Route exact path="/upcoming-news">
        <WrapperScroll Component={UpComingNews} />
      </Route>
      <Route path="/upcoming-news/:id">
        <WrapperScroll Component={SingleNews} />
      </Route>
      {/* <Route path="/upcoming-akcia">
                <UpcomingActions />
            </Route> */}
      <Route path="/reserved-points">
        <WrapperScroll Component={ReservedPoints} />
      </Route>
      <Route path="/reports">
        <WrapperScroll Component={Reports} />
      </Route>
      <Route path="/contacts">
        <WrapperScroll Component={Contacts} />
      </Route>
      <Route path="/sitemap">
        <WrapperScroll Component={AppMap} />
      </Route>
      <Route path="/*">
        <WrapperScroll Component={PageNotFound} />
      </Route>
    </Switch>
  );
}
export default Routes;
