
import React, { useState } from 'react';
import cs from 'classnames';
import Preloader from '../Preloader';
import FormData from 'form-data';
import { AvInput } from 'availity-reactstrap-validation';
import IconFile from '../../assets/img/icons-file.png';
import IconTrash from '../../assets/img/icons-trash.png';
import { uploadAttachments, unlinkImage } from '../../actions/file';

const ImageUpload = ({ name, initialValue, removable, size, disabled, className = '', url }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [value, setValue] = useState(initialValue || '');

  const handleChange = ({ target: { files } }) => {
    const image = files[0];
    const reader = new FileReader();
    const formData = new FormData();
    formData.append('file', image);
    reader.onload = () => {
      setLoading(true);
      uploadAttachments(url, formData)
        .then(({ data: { data } }) => {
          if (data) {
            setValue(data.name);
            setError(false)
          }
        }).catch(setError(true))
        .finally(() => {
          setLoading(false);
        });
    };
    if (image) {
      reader.readAsDataURL(image);
    }
  };
  const handleDelete = () => {
    unlinkImage(value).then(() => {
      setValue('');
    });
  };
  return (
    <div
      className={cs('image-upload', className, { disabled, empty: !value,  }, error ? 'error' : '')}
      style={size ? { width: size + 'px', height: size + 'px' } : {}}
    >
      <AvInput type="hidden" name={name} value={value} />
      <label>
        {!!value ?
          <div className="file">
            <div className="icon-files">
              <p className="image-title">{value}</p>
            </div>
            <div className="icon-files" onClick={() => setValue('') }>
              <img src={IconTrash} alt="icon"/>
            </div>
          </div>
          : <div className="empty-value">
              <img src={IconFile} alt="icon"/>
              <p className="">Ներբեռնել ֆայլ</p>
          </div>
      }
        <input type="file" onChange={handleChange} disabled={disabled} accept="image/png, image/jpeg" />
        <span className={cs({ 'image-loading': loading, empty: !value })}>
          {loading ? <Preloader /> : <i className="icon icon-camera"></i>}
        </span>
      </label>
      {/* {!disabled && removable && !!value && (
        <Button color="danger" size="sm" onClick={handleDelete} className="delete-image p-1">
          <i className="icon icon-trash-2"></i>
        </Button>
      )} */}
    </div>
  );
};

export default ImageUpload;
